
.producer_chip {
  color: #6b3140 !important;
}
.weight_light {
  background-color: #f5c4d1 !important;
  color: white !important;
  font-weight: 600;
}

.weight_medium {
  background-color: #6b3140 !important;
  color: white !important;
  font-weight: 600;
}

.weight_heavy {
  background-color: #28000a !important;
  color: white !important;
  font-weight: 600;
}