.cardHeader {
  padding-bottom: 0 !important;
}
.cardContent {
  padding-top: 0px !important;
  padding-bottom: 0 !important;
}
.mainContent{
  margin-bottom: 10px;
}
.productImage {
  object-fit: cover;
  height: 100%;
  margin-right: 10px;
}
.aboutText{
  font-size: 14px;
  line-height: 1.65;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  display: -webkit-box;
  -webkit-line-clamp: 7; 
  -webkit-box-orient: vertical; 
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 160px; 
  margin-bottom: 10px;
}
.priceText {
  font-weight: '600';
}

.actionRow {
  margin-top: 15px;
  align-items: right;
}

.priceBtn{
  background-color: #6b3140 !important;
  color: white !important;
  font-weight: 600;
  width: 120px !important;
  border-radius: 20px !important;
  margin: 10px 0 !important;
}
.learnBtn {
  color: #6b3140 !important;
  font-weight: 600;
  font-size: 13px !important;
}
.producer_chip {
  color: #6b3140 !important;
}
.weight_light {
  background-color: #f5c4d1 !important;
  color: white !important;
  font-weight: 600;
}

.weight_medium {
  background-color: #6b3140 !important;
  color: white !important;
  font-weight: 600;
}

.weight_heavy {
  background-color: #28000a !important;
  color: white !important;
  font-weight: 600;
}